export function siteScroll() {
  if (!("scrollBehavior" in document.documentElement.style)) {
    const pageLinks = document.querySelectorAll('[href^="#"]');
    pageLinks.forEach((link) => link.addEventListener("click", scrollToTarget));
  }
}

function scrollToTarget(evt) {
  let scrollToElem = evt.target.hash;
  if (scrollToElem.startsWith("#")) {
    document.querySelector(scrollToElem).scrollIntoView({
      behavior: "smooth",
    });
  }

  if (evt.target.id === "to-top") {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
