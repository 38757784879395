const toTop = document.querySelector("#to-top");
let latestKnownScrollY = 0,
  ticking = false;

if (toTop !== undefined) {
  toTop.classList.add("is--hidden");
}

function update() {
  if (latestKnownScrollY > 280) {
    toTop.classList.remove("is--hidden");
  } else {
    toTop.classList.add("is--hidden");
  }
  ticking = false;
}

function onScroll() {
  latestKnownScrollY = window.scrollY;
  requestTick();
}

function requestTick() {
  if (!ticking) {
    requestAnimationFrame(update);
  }
  ticking = true;
}

window.addEventListener("scroll", onScroll, false);
